<template>
  <tr>
    <td>
      <tp-text-field
        v-model="internalSku.SKU"
        dense
        hide-details
        outlined
        placeholder="Mã SKU"
      ></tp-text-field>
    </td>

    <td>
      <tp-select
        v-model="internalSku.specs_id"
        :items="product.specs"
        hide-details
        item-text="name"
        item-value="id"
        placeholder="Phiên bản"
      ></tp-select>
    </td>

    <td>
      <autocomplete-color
        v-model="internalSku.color.id"
        hide-details
      ></autocomplete-color>
    </td>

    <td>
      <tp-select
        v-model="internalSku.nguon_hang"
        :items="sources"
        hide-details
        item-text="label"
        item-value="value"
        placeholder="Nguồn hàng"
      ></tp-select>
    </td>

    <td>
      <tp-input-price
        v-model="internalSku.price"
        dense
        hide-details
        outlined
        placeholder="Nhập giá"
      ></tp-input-price>
    </td>

    <td>
      <v-autocomplete
        v-model="internalSku.tinh_trang"
        :items="usingStatus"
        dense
        hide-details
        hide-selected
        item-text="name"
        item-value="id"
        no-data-text="Không có dữ liệu"
        outlined
        placeholder="Chọn tình trạng máy"
      ></v-autocomplete>
    </td>

    <td>
      <v-checkbox
        v-model="internalSku.ttkd"
        :false-value="1"
        :true-value="0"
        class="mt-0"
        hide-details
      ></v-checkbox>
    </td>

    <td class="text-center">
      <v-chip
        v-if="status"
        :color="status.background"
        :text-color="status.color"
        class="font-weight-bold px-2 ml-2"
        small
      >
        {{ status.label }}
      </v-chip>

      <span v-else>N/A</span>
    </td>

    <td>
      <slot name="action" v-bind="{ state: internalSku, reset }">
        <div v-if="isChanged" class="d-flex">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                icon
                v-bind="attrs"
                @click="updateSku"
                v-on="on"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </template>

            <span>Lưu</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="red" icon v-bind="attrs" @click="reset" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>

            <span>Huỷ</span>
          </v-tooltip>
        </div>

        <div v-else class="d-flex">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                v-bind="attrs"
                @click="openModalProductOption(internalSku.id)"
                v-on="on"
              >
                <v-icon>mdi-square-edit-outline</v-icon>
              </v-btn>
            </template>

            <span>Chỉnh sửa</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="rounded"
                color="primary"
                icon
                v-bind="attrs"
                @click="duplicateProductOption(internalSku.id)"
                v-on="on"
              >
                <v-icon>mdi-content-duplicate</v-icon>
              </v-btn>
            </template>
            <span>Thêm bản sao</span>
          </v-tooltip>

          <button-view-sku-in-website
            :urls="internalSku.urls"
            class="ml-2"
          ></button-view-sku-in-website>
        </div>
      </slot>
    </td>
  </tr>
</template>

<script>
import { computed, defineComponent } from "vue";
import { cloneDeep, buildConstants } from "@vt7/utils";
import { useResetRef } from "@vt7/composables";

import { createVuexHelpers } from "@/core/composables";
import ProductOptionService from "@/modules/Goods/service/product-option.service";

import AutocompleteColor from "./AutocompleteColor.vue";
import {
  PRODUCT_OPTION_TABS_INDEX,
  SKU_NGUON_HANG,
  SKU_STATUS
} from "@/core/constant";
import ButtonViewSkuInWebsite from "@/core/components/modals/ModalProduct/components/ButtonViewSkuInWebsite.vue";

export default defineComponent({
  components: { ButtonViewSkuInWebsite, AutocompleteColor },

  props: {
    sku: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      usingStatus: [
        { id: 1, name: "New Sealed" },
        { id: 2, name: "New, Fullbox" },
        { id: 3, name: "New, Outlet" },
        { id: 4, name: "Used" }
      ]
    };
  },

  setup(props) {
    const { state: internalSku, reset, isChanged } = useResetRef(
      () => props.sku
    );

    const { useGetters } = createVuexHelpers();
    const { product } = useGetters("PRODUCT", ["product"]);
    const { productOption } = useGetters("PRODUCT_OPTION", ["productOption"]);

    return {
      internalSku,
      product,
      productOption,
      reset,
      isChanged,
      sources: Object.values(SKU_NGUON_HANG),
      status: computed(() => {
        return buildConstants(SKU_STATUS, "value")[internalSku.value.status];
      })
    };
  },

  methods: {
    async updateSku() {
      try {
        await ProductOptionService.updateProductOption({
          ...this.internalSku,
          product_id: this.product.id
        });

        this.$emit("update:sku", cloneDeep(this.internalSku));

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Cập nhật sku thành công"
          }
        });
      } catch (e) {
        console.log("onUpdateSpecs", e);

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: e.response.data
          }
        });
      }
    },
    async openModalProductOption(id) {
      if (id) {
        await this.$store.dispatch("PRODUCT_OPTION/getProductOptionById", {
          productOptionId: id,
          productCategoryId: this.product.category_id
        });

        // const response = await this.$store.dispatch(
        //   "MODEL_SITE_CONFIG/getModelSiteConfigsByModelId",
        //   {
        //     modelId: id,
        //     modelType: LIST_MODEL_TYPES.PRODUCT_OPTION
        //   }
        // );

        // await this.$store.dispatch(
        //   "PRODUCT_OPTION/setProductOptionModelSiteConfigs",
        //   response
        // );
      }

      this.$modal.show({
        name: "modal-product-option"
      });
    },

    async duplicateProductOption(id) {
      this.$store.dispatch(
        "PRODUCT_OPTION/setActiveTab",
        PRODUCT_OPTION_TABS_INDEX.GENERAL
      );

      await this.$store.dispatch("PRODUCT_OPTION/getProductOptionById", {
        productOptionId: id,
        productCategoryId: this.product.category_id
      });

      this.productOption.id = null;

      this.$modal.show({
        name: "modal-product-option"
      });
    }
  }
});
</script>
